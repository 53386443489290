const activityTypes = {
  UPDATE_PAY_PLAN_CHANGE_REQUEST_SUCCESS: {
    url: (id: string) => `/payplanchangerequest/${id}`,
    dataReference: "payPlanChangeRequestId",
    urlTitle: "View Change Request"
  },
  UPDATE_POLICY_CHANGE_REQUEST_SUCCESS: {
    url: (id: string) => `/propertychangerequest/${id}`,
    dataReference: "policyChangeRequestId",
    urlTitle: "View Change Request"
  },
  UPDATE_CANCELLATION_CHANGE_REQUEST_SUCCESS: {
    url: (id: string) => `/billingchangerequest/${id}`,
    dataReference: "cancellationChangeRequestId",
    urlTitle: "View Change Request"
  },
  UPDATE_CLAIMS_REQUEST_SUCCESS: {
    url: (id: string) => `/lossformadmin/edit/${id}`,
    dataReference: "claimsRequestId",
    urlTitle: "View Claims"
  },
  UPDATE_MORTGAGE_SUCCESS: {
    url: (id: string) => `/admin/mortgages/${id}`,
    dataReference: "mortgageId",
    urlTitle: "View Mortgage"
  },
  UPDATE_UNDERWRITING_QUESTION_SUCCESS: {
    url: (id: string) => `/admin/underwriting-questions/${id}/edit`,
    dataReference: "underWritingQuestionId",
    urlTitle: "View Underwriting Question"
  },
  UPDATE_QUOTE_SUCCESS: {
    url: (id: string) => `/quotes/${id}`,
    dataReference: "quoteId",
    urlTitle: "View Quote"
  },
  UPDATE_QUOTE_COUNTY_VALIDATION_SUCCESS: {
    url: (id: string) => `/admin/quoteCountyValidation/${id}`,
    dataReference: "quoteCountyValidationId",
    urlTitle: "View Validation"
  },
  UPDATE_AGENCY_SUCCESS: {
    urlTitle: "View Agency",
    dataReference: "agencyId",
    url: (id: string) => `/admin/agencies/${id}`
  },
  UPDATE_ACCREDITED_BUILDER_SUCCESS: {
    urlTitle: "View Accredited Builder",
    dataReference: "accreditedBuilderId",
    url: (id: string) => `/admin/${id}/accreditedBuilder`
  },
  UPDATE_COMPANY_SUCCESS: {
    urlTitle: "View Company",
    dataReference: "companyId",
    url: (id: string) => `/admin/companies/${id}`
  },
  UPDATE_ENDORSEMENT_SUCCESS: {
    urlTitle: "View Endorsement",
    dataReference: "endorsementId",
    url: (id: string) => `/admin/endorsements/${id}/edit`
  }
};
export function formatQuoteUpdateText(data: {
  requiredData: string | any[];
  linkColor: string;
  field: string;
  activity: any;
  requireFormatting?: boolean;
  userLink?: string;
}): string {
  const {
    linkColor = "",
    activity = {},
    field = "",
    requireFormatting = false,
    requiredData = "",
    userLink = "{userName}"
  } = data;
  const activityType: { url: any; dataReference: string; urlTitle: string } =
    activityTypes[activity.name as keyof typeof activityTypes];

  const activityUrl = activityType?.url(
    activity.data[activityType.dataReference]
  );
  const requireFormatText = requireFormatting
    ? requiredData.toString().replaceAll(",", ", ")
    : requiredData;
  let formatted = `<p>${userLink} updated ${field} to: ${requireFormatText}`;
  if (activityType && activityType.dataReference) {
    formatted += `| <a href="${activityUrl}" style="color: ${linkColor}" class="font-bold" target="_blank">${activityType?.urlTitle}</a></p>`;
  }
  return formatted;
}
