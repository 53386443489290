<template>
  <div>
    <el-timeline-item
      v-if="content"
      :icon="icon"
      :type="type"
      :color="color"
      size="large"
      :timestamp="formatDate(activity.createdOn)"
    >
      <div v-html="formattedActivityText" class=""></div>
    </el-timeline-item>
  </div>
</template>

<script>
import { companiesMapGetters } from "@/store/modules/companies";
import { authMapGetters } from "@/store/modules/auth";
import ActivitiesTimelineMixin from "@/components/ActivitiesView/ActivitiesTimelineMixin.vue";

export default {
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  mixins: [ActivitiesTimelineMixin],
  methods: {
    getCompany(companyId) {
      const matchedCompany = this.getCompanyById(companyId);
      return matchedCompany ? matchedCompany.companyName : "N/A";
    },
    async generateReadableUpdateAgency() {
      let contentHtml = ``;
      let companiesArray = [];
      for (let update of this.updates) {
        if (update.field.includes("companyIds")) {
          companiesArray.push(this.getCompany(update.update));
        } else if (update.field.includes("underWriterId")) {
          const user = await this.getUser(update.update);
          const href = this.baseLink(update.update);
          contentHtml =
            contentHtml +
            `<p>{userName} updated the agency's underwriter:
            <a style="color: ${this.linkColorDark}" href="${href}"> ${user.fullName}</a></p>`;
        } else {
          contentHtml = this.generateRegularActivityString(contentHtml, update);
        }
      }
      if (companiesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated the agency's companies: ${companiesArray.toString()}</p>`;
      }
      this.setReadableUpdateProps(contentHtml);
    },
    generateReadableUpdateCompany() {
      let contentHtml = ``;
      let linesOfBusinessArray = [];
      let HOFormTypesArray = [];
      let DFFormTypesArray = [];
      let TCFormTypesArray = [];
      let companyPrefixArray = [];

      for (let update of this.updates) {
        if (update.field.includes("companyLineOfBusiness")) {
          linesOfBusinessArray.push(update.update);
        } else if (update.field.includes("formName")) {
          switch (update.field[0]) {
            case "H":
              HOFormTypesArray.push(update.update);
              break;
            case "D":
              DFFormTypesArray.push(update.update);
              break;
            case "T":
              TCFormTypesArray.push(update.update);
              break;
          }
        } else if (update.field.includes(".value")) {
          continue;
        } else if (update.field.includes("companyPrefix")) {
          companyPrefixArray.push(update.update);
        } else {
          contentHtml = this.generateRegularActivityString(contentHtml, update);
        }
      }
      if (linesOfBusinessArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated the companies' lines of business. ${linesOfBusinessArray.toString()}</p>`;
      }
      if (HOFormTypesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated Homeowner form types. ${HOFormTypesArray.toString()}</p>`;
      }
      if (DFFormTypesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated Dwelling fire form types. ${DFFormTypesArray.toString()}</p>`;
      }
      if (TCFormTypesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated Tenant/Condo form types. ${TCFormTypesArray.toString()}</p>`;
      }
      if (companyPrefixArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated the company prefixes. ${companyPrefixArray.toString()}</p>`;
      }
      this.setReadableUpdateProps(contentHtml);
    },
    generateReadableUpdateAccreditedBuilder() {
      let contentHtml = ``;
      let accreditedBuilderName = [];

      for (let update of this.updates) {
        if (update.field.includes("accreditedBuilderName")) {
          accreditedBuilderName.push(update.update);
        } else {
          contentHtml = this.generateRegularActivityString(contentHtml, update);
        }
      }

      this.setReadableUpdateProps(contentHtml);
    },
    generateReadableUpdateEndorsement() {
      let contentHtml = ``;
      let companiesArray = [];
      let formTypesArray = [];
      let appliesToArray = [];

      for (let update of this.updates) {
        if (update.field.includes("companies")) {
          const company = this.getCompanyByCompanyNumber(
            parseInt(update.update)
          );
          companiesArray.push(company.companyName);
        } else if (update.field.includes("formTypes")) {
          formTypesArray.push(update.update);
        } else if (update.field.includes("appliesTo")) {
          appliesToArray.push(update.update);
        } else {
          contentHtml = this.generateRegularActivityString(contentHtml, update);
        }
      }
      if (companiesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated companies: ${companiesArray.toString()}</p>`;
      }
      if (formTypesArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated form types: ${formTypesArray.toString()}</p>`;
      }
      if (appliesToArray.length) {
        contentHtml =
          contentHtml +
          `<p>{userName} updated lines of business: ${appliesToArray.toString()}</p>`;
      }
      this.setReadableUpdateProps(contentHtml);
    }
  },
  computed: {
    ...companiesMapGetters(["getCompanyById"]),
    ...authMapGetters(["getCompanyByCompanyNumber"])
  },
  watch: {
    activity: {
      async handler() {
        this.content = "";
        this.icon = "";
        this.type = "";
        this.link = {};
        this.color = "";
        switch (this.activity.name) {
          case "ADD_POLICY_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "created this policy change request.",
              icon: "el-icon-document-add",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_UNDERWRITING_QUESTION_SUCCESS":
          case "ADD_BULK_UNDERWRITING_QUESTION_SUCCESS":
            this.generateReadableGeneric({
              content: "created this underwriting question.",
              icon: "el-icon-document-add",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_PAY_PLAN_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "created this pay plan change request.",
              icon: "el-icon-document-add",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_CANCELLATION_CHANGE_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "created this cancellation change request.",
              icon: "el-icon-document-add",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_CLAIMS_REQUEST_SUCCESS":
            this.generateReadableGeneric({
              content: "created this claims request.",
              icon: "el-icon-document-add",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_AGENCY_SUCCESS":
            this.generateReadableGeneric({
              content: "created this agency.",
              icon: "el-icon-s-home",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_AGENCY_SUCCESS":
            await this.generateReadableUpdateAgency();
            break;
          case "ADD_COMPANY_SUCCESS":
            this.generateReadableGeneric({
              content: "created this company.",
              icon: "el-icon-office-building",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_COMPANY_SUCCESS":
            this.generateReadableUpdateCompany();
            break;
          case "ADD_ACCREDITED_BUILDER_SUCCESS":
            this.generateReadableGeneric({
              content: "created an accredited builder.",
              icon: "el-icon-office-building",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_ACCREDITED_BUILDER_SUCCESS":
            this.generateReadableUpdateCompany();
            break;
          case "ADD_QUOTE_COUNTY_VALIDATION_SUCCESS":
            this.generateReadableGeneric({
              content: "created a quote validation.",
              icon: "el-icon-office-building",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_QUOTE_COUNTY_VALIDATION_SUCCESS":
            this.generateReadableUpdateCompany();
            break;
          case "ADD_MORTGAGE_SUCCESS":
            this.generateReadableGeneric({
              content: "added this mortgage.",
              icon: "el-icon-office-building",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "ADD_ENDORSEMENT_SUCCESS":
            this.generateReadableGeneric({
              content: "added this endorsement.",
              icon: "el-icon-circle-check",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_ENDORSEMENT_SUCCESS":
            this.generateReadableUpdateEndorsement();
            break;
          case "ADD_COUNTY_SUCCESS":
            this.generateReadableGeneric({
              content: "added this county.",
              icon: "el-icon-circle-check",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "UPDATE_PAY_PLAN_CHANGE_REQUEST_SUCCESS":
          case "UPDATE_POLICY_CHANGE_REQUEST_SUCCESS":
          case "UPDATE_CANCELLATION_CHANGE_REQUEST_SUCCESS":
          case "UPDATE_CLAIMS_REQUEST_SUCCESS":
          case "UPDATE_MORTGAGE_SUCCESS":
          case "UPDATE_COUNTY_SUCCESS":
          case "UPDATE_UNDERWRITING_QUESTION_SUCCESS":
            this.generateReadableUpdate();
            break;
          case "UPDATE_PAY_PLAN_CHANGE_REQUEST_FAILURE":
          case "UPDATE_POLICY_CHANGE_REQUEST_FAILURE":
          case "UPDATE_CANCELLATION_CHANGE_REQUEST_FAILURE":
          case "UPDATE_CLAIMS_REQUEST_FAILURE":
          case "UPDATE_MORTGAGE_FAILURE":
          case "UPDATE_ENDORSEMENT_FAILURE":
          case "UPDATE_AGENCY_FAILURE":
          case "UPDATE_COMPANY_FAILURE":
          case "UPDATE_UNDERWRITING_QUESTION_FAILURE":
          case "UPDATE_ACCREDITED_BUILDER_FAILURE":
          case "UPDATE_QUOTE_COUNTY_VALIDATION_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to update.",
              icon: "el-icon-error",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_PAY_PLAN_CHANGE_REQUEST_FAILURE":
          case "DELETE_POLICY_CHANGE_REQUEST_FAILURE":
          case "DELETE_CANCELLATION_CHANGE_REQUEST_FAILURE":
          case "DELETE_CLAIMS_REQUEST_FAILURE":
          case "DELETE_MORTGAGE_FAILURE":
          case "DELETE_ENDORSEMENT_FAILURE":
          case "DELETE_AGENCY_FAILURE":
          case "DELETE_COMPANY_FAILURE":
          case "DELETE_UNDERWRITING_QUESTION_FAILURE":
          case "DELETE_ACCREDITED_BUILDER_FAILURE":
          case "DELETE_QUOTE_COUNTY_VALIDATION_FAILURE":
            this.generateReadableFailGeneric({
              content: "failed to delete.",
              icon: "el-icon-error",
              link: this.accountTypeFormatted
            });
            break;
          case "ASSIGN_USER_TO_AGENCY_SUCCESS":
            {
              const agentFormattedText =
                this.data && this.data.agentIds && this.data.agentIds.length > 1
                  ? "agents"
                  : "agent";
              this.generateReadableGeneric({
                content: `assigned ${this.data.agentIds.length} new ${agentFormattedText} to agency.`,
                icon: "el-icon-circle-check",
                type: "success",
                link: this.accountTypeFormatted
              });
            }
            break;
          case "ASSIGN_USER_TO_AGENCY_FAILURE":
            {
              this.generateReadableFailGeneric({
                content: "failed to assign new agents to agency.",
                icon: "el-icon-error",
                link: this.accountTypeFormatted
              });
            }
            break;
        }
      },
      immediate: true
    }
  }
};
</script>

<style></style>
